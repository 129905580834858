import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const siteUsageByUser = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    scrollX: true,
    buttons: [
      "colvis",
      "copyHtml5",
      {
        extend: "csvHtml5",
        title: "Site Usage By User",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "Site Usage By User",
        exportOptions: {
          columns: ":visible",
        },
      },
      // "excelHtml5",
      // "pdfHtml5",
    ],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "first_name", title: "First Name" },
      { data: "last_name", title: "Last Name" },
      { data: "email", title: "Email" },
      { data: "company", title: "Company" },
      { data: "country", title: "Country" },
      { data: "day_registered", title: "Date Registered" },
      { data: "last_access_date", title: "Last Access Date" },
      { data: "days_since_last_access", title: "Days Since Last Access" },
      { data: "certified", title: "Certified (Y/N)" },
      { data: "total_certifications", title: "Total Certifications" },
      { data: "total_time_spent_(hours)", title: "Total Time Spent (Hours)" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
