import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const courseRetention = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    scrollX: true,
    buttons: [
      "colvis",
      "copyHtml5",
      {
        extend: "csvHtml5",
        title: "Course Retention",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "Course Retention",
        exportOptions: {
          columns: ":visible",
        },
      },
      // "excelHtml5",
      // "pdfHtml5",
    ],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "course_name", title: "Course Name" },
      { data: "category_name", title: "Category Name" },
      { data: "total_enrolments", title: "Total Enrolments" },
      { data: "average_rating", title: "Average Rating" },
      { data: "variation", title: "Variation" },
      { data: "course_retention", title: "Course Retention" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
